<template>
  <main id="case-study" :class="`case-study--${caseStudy.slug}`">
    <div class="page page--subpage page--hero page--hero--case-study" :style="{backgroundColor: caseStudy.accent, minHeight: !caseStudyHero ? '60vh' : null}" :data-brightness="evaluateBrightness(caseStudy.accent)">
      <div v-if="caseStudyHero" class="hero__image">
        <responsive-image :name="caseStudyHero" :alt="`${caseStudy.title} ${caseStudy.service}`" v-if="responsiveHero" />
        <img v-else :src="caseStudyHero" :alt="`${caseStudy.title} ${caseStudy.service}`">
      </div>
      <div v-else class="wrapper">
        <div class="hero__content">
          <p class="like-h1">
            {{ caseStudy.title }}
          </p>
        </div>
      </div>
    </div>

    <PageBlock class="case-study" block-title="BackBtn" :block-columns="['Our Work']" :block-columns-link="['/#our-work']" data-brightness="light" :grid="[1,5,11,12]" :backTo="backTo">
      <h1>{{ caseStudy.title }}</h1>
      <template v-slot:secondary>
        <div class="anim">
          <div v-html="caseStudy.content" class="body-content"></div>
          <figure class="inline-gallery" v-for="item, index in caseStudy['inline-gallery']" :key="index">
            <img :src="require(`../assets/case-study/${item.image}`)" :alt="`${item.alt}`">

            <figcaption v-if="item.caption">{{ item.caption }}</figcaption>
          </figure>
        </div>
        <social-share :post="caseStudy" />
      </template>
      <template v-slot:end>
        <div class="gallery--wrapper">
          <component class="gallery" v-for="item, index in caseStudy.gallery" :key="index" :item="item" :is="galleryComponent(item)"/>
        </div>
      </template>
    </PageBlock>
    <div class="related" v-if="related">
      <GridLines :columns="[1,11,12]" />
      <div class="wrapper">
        <h2>More Case Studies</h2>
        <div class="related--posts">
          <CaseStudyThumbnail class="related-work--item" v-for="(item, index) in related" :key="index" :item="item" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import work from '@/assets/work.yaml';
import PageBlock from '@/components/PageBlock.vue'
import GridLines from '@/components/GridLines.vue';
import CaseStudyThumbnail from '@/components/CaseStudyThumbnail.vue';
import SocialShare from '@/components/SocialShare.vue';
import GalleryFit from '@/components/GalleryFit.vue';
import GalleryWide from '@/components/GalleryWide.vue';
import GalleryWebsite from '@/components/GalleryWebsite.vue';

export default {
  name: 'CaseStudy',
  components: {
    PageBlock,
    GridLines,
    CaseStudyThumbnail,
    SocialShare,
    GalleryFit,
    GalleryWide,
    GalleryWebsite
  },
  mounted () {
    if (!this.work[this.$route.params.slug] || !this.work[this.$route.params.slug].visibility) {
      this.$router.push('/404');
      return
    }
  },
  computed: {
    allWork () {
      return this._sortBy(
        this._filter(
          this._map(this.work, (w, slug) => {
            w.slug = slug;
            return w;
          }),
          { visibility: { grid: true } }
        )
      , 'position')
    },
    related () {
      let related = []
      if (this.caseStudy.related) {
        related = this.caseStudy.related.map((slug) => {
          return this.allWork.find((work) => work.slug === slug);
        });
        related = related.filter(function( work ) {
          return work !== undefined;
        });
      }
      return related.concat(this._shuffle(this.allWork.filter((work) => work.slug !== this.$route.params.slug)).slice(0, 3 - related.length));
    },
    caseStudy () {
      const caseStudy = this.work[this.$route.params.slug]

      caseStudy.slug = this.$route.params.slug;

      return caseStudy;
    },
    backTo() {
      return '/#our-work';
    },
    caseStudyHero() {
      if (this.caseStudy.accent) {
        if (this.evaluateBrightness(this.caseStudy.accent) === 'light') {
          document.body.classList.add('page-color--light');
        }
      }

      if (this.caseStudy.hero && /\.(jpg|png)$/.test(this.caseStudy.hero)) {
        return require(`../assets/case-study/${this.caseStudy.hero}`);
      } else if (this.caseStudy.hero) {
        return this.caseStudy.hero;
      }
      return false;
    },
    responsiveHero() {
      if (this.caseStudy.hero && /\.(jpg|png)$/.test(this.caseStudy.hero) == false) {
        return true;
      }
      return false;
    }
  },
  data () {
    return {
      work
    }
  },
  methods: {
    evaluateBrightness(hex) {
      const r = parseInt(hex.substring(1, 3), 16);
      const g = parseInt(hex.substring(3, 5), 16);
      const b = parseInt(hex.substring(5, 7), 16);
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      return brightness >= 230 ? 'light' : 'dark';
    },
    galleryComponent(item) {
      switch (item.layout) {
        case 'fit':
          return 'GalleryFit';
        case 'website':
          return 'GalleryWebsite';
        case 'wide':
          return 'GalleryWide';
        default:
          return 'GalleryFit';
      }
    }
  },
  metaInfo() {
    return {
      title: this.caseStudy.seo.title,
      meta: [
        { name: 'description', content: this.caseStudy.seo.description },
        { property: 'og:title', content: `${this.caseStudy.seo.title} : Octopus Ink` },
        { property: 'og:site_name', content: 'Octopus Ink' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://octopusink.ca/our-work/${this.caseStudy.slug}` },
        { property: 'og:image', content: 'https://octopusink.ca' + require(`../assets/work/main-${this.caseStudy.slug}.jpg`) },
        { property: 'og:description', content: this.caseStudy.seo.description },

        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: `https://octopusink.ca/our-work/${this.caseStudy.slug}` },
        { name: 'twitter:title', content: `${this.caseStudy.seo.title} : Octopus Ink` },
        { name: 'twitter:description', content: this.caseStudy.seo.description },

        { name: 'twitter:image:src', content: 'https://octopusink.ca' + require(`../assets/work/main-${this.caseStudy.slug}.jpg`) },

        { itemprop: 'name', content: `${this.caseStudy.seo.title} : Octopus Ink` },
        { itemprop: 'description', content: this.caseStudy.seo.description },
        { itemprop: 'image', content: 'https://octopusink.ca' + require(`../assets/work/main-${this.caseStudy.slug}.jpg`) }
      ],
      link: [
        { rel: 'canonical', href: `https://octopusink.ca/our-work/${this.caseStudy.slug}` }
      ]
    }
  }
}
</script>
